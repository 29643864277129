<template>
  <a-menu
    v-model="currentMenu"
    :open-keys="openedMenuKeys"
    class="menus-box"
    mode="inline"
    theme="dark"
    @openChange="onOpenChange"
  >
    <a-menu-item key="home" class="item">
      <a-icon type="home" />
      <router-link :to="{ name: 'home' }">
        <span>首页</span>
      </router-link>
    </a-menu-item>

    <a-menu-item key="customers" class="item">
      <a-icon type="team" />
      <router-link :to="{ name: 'customers' }">
        <span>客户管理</span>
      </router-link>
    </a-menu-item>

    <a-sub-menu key="devices" class="sub-item">
      <span slot="title">
        <a-icon type="gold" />
        <span>设备中心</span>
      </span>

      <a-menu-item key="gateways">
        <router-link :to="{ name: 'gateways' }">
          <span>集抄器</span>
        </router-link>
      </a-menu-item>

      <a-menu-item key="terminals">
        <router-link :to="{ name: 'terminals' }">
          <span>终端设备</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>

    <a-sub-menu v-if="isShowMenu('admin/users')" key="system_config" class="sub-item">
      <span slot="title">
        <a-icon type="setting" />
        <span>系统设置</span>
      </span>

      <a-menu-item key="users">
        <router-link :to="{ name: 'users' }">
          <span>用户管理</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</template>

<script>
import { hasPermission } from '@/utils/permission'

export default {
  name: 'LayoutSiderPlatformMenus',
  data() {
    return {
      rootSubmenuKeys: [
        'users'
      ],
      openedMenuKeys: this.$store.getters.currentMenus
    }
  },
  computed: {
    // 当前选中的菜单
    currentMenu: {
      get() {
        return this.$store.getters.currentMenus
      },
      set(val) {
        // setActiveMenu 中设置
        // 这里给一个空的 setter, 解决在新标签打开菜单报错问题
      }
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.openedMenuKeys = this.currentMenu
      }
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    onOpenChange(openedMenuKeys) {
      const latestOpenKey = openedMenuKeys.find(key => this.openedMenuKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openedMenuKeys = openedMenuKeys
      } else {
        this.openedMenuKeys = latestOpenKey ? [latestOpenKey] : []
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/styles/scopped/sider.less';
</style>
